module.exports = {
  email: 'iam@rafayhingoro.me',
  siteMetadata: {
    title: 'Rafay Hingoro',
    description: 'Hey, I\'m Rafay - a UK-based software engineer who is passionate about developing exceptional websites and applications that are both user-friendly and secure. My goal is to provide my clients with high-quality software solutions that meet their unique needs and exceed their expectations. Whether it\'s developing custom web applications or optimizing e-commerce systems, I\'m dedicated to delivering results that are reliable, efficient, and visually appealing. With years of experience under my belt, I\'m always looking for new challenges and opportunities to learn and grow as a software developer.',
    siteUrl: 'https://rafayhingoro.me',
    image: '',
    twitter: '',
  },
  gaID: 'ua-1291239', // google-analytics id
  socialMedia: [
    {
      name: 'GitHub',
      url: 'https://github.com/rafayhingoro',
    },
    {
      name: 'Instagram',
      url: 'https://www.instagram.com/thisishingoro',
    },
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/rafayhingoro',
    },
    {
      name: 'Codepen',
      url: 'https://codepen.io/hingoro',
    },
    {
      name: 'Upwork',
      url: 'https://www.upwork.com/freelancers/~0130b2432c579809a8',
    },
  ],

  navLinks: [
    {
      name: 'About',
      url: '/#about',
    },
    {
      name: 'Experience',
      url: '/#jobs',
    },
    {
      name: 'Work',
      url: '/#projects',
    },
    {
      name: 'Contact',
      url: '/#contact',
    },
    {
      name: 'Blog',
      url: '/blogs',
    },
  ],

  colors: {
    green: '#64ffda',
    navy: '#0a192f',
    darkNavy: '#020c1b',
  },

  srConfig: (delay = 200, viewFactor = 0.25) => ({
    origin: 'bottom',
    distance: '20px',
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    reset: false,
    useDelay: 'always',
    viewFactor,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};
