import React from 'react';

const IconLoader = () => (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="960px" height="560px" viewBox="0 0 960 560" enableBackground="new 0 0 960 560" xmlSpace="preserve">
         
<path fill="#C9D1E8" d="M356.729,246.332c1.335,0.188,3.013-0.076,3.955,0.635c11.93,8.993,25.086,13.513,40.235,13.052
	c17.017-0.517,34.061-0.096,51.094-0.084c2.25,0.002,4.501,0,6.883,0c-0.602-3.638-1.061-6.418-1.725-10.433
	c-14.748,0-31.166,0-48.074,0c-0.144-2.928-0.255-5.175-0.148-8.479c0.388-4.141,0.558-7.223,0.728-10.306
	c2.11-22.028,14.921-34.979,35.025-42.329c9.886-3.614,19.328-8.442,28.925-12.701c0,31.803,0,65.104,0,98.869
	c-1.839,0.096-3.892,0.293-5.945,0.295c-23.75,0.021-47.502-0.172-71.25,0.087c-12.953,0.141-24.556-4.067-36.076-9.258
	c-5.646-2.544-2.32-7.302-3.551-12.119C356.701,250.364,356.715,248.348,356.729,246.332 M451.248,234.197c2.486,0,4.973,0,7.796,0
	c0-12.219,0-23.711,0-35.243c-21.273,2.35-37.739,18.538-34.103,35.243C433.19,234.197,441.526,234.197,451.248,234.197z"/>
<path fill="#C9D1E8" d="M316.233,196.193c5.482-0.81,9.676-2.526,13.402-7.731c4.618-6.45,11.675-11.155,17.763-16.69
	c5.721,6.337,12.34,13.703,19.019,21.015c0.708,0.775,1.708,1.635,2.67,1.771c8.3,1.177,12.639,6.78,14.667,14.057
	c2.029,7.275,3.011,14.842,4.619,23.14c-12.943-0.637-24.102,0.722-33.63,9.885c-3.061,2.943-5.474,5.31-8.221,7.742
	c-0.683,0.376-0.925,0.754-1.005,1.182c0.055-0.019,0.006-0.124,0.006-0.124c-4.634,6.334-9.88,12.318-13.756,19.086
	c-5.388,9.408-3.05,18.891,2.207,27.731c8.056,13.548,20.561,22.488,33.845,31.861c2.4,1.716,4.805,2.73,5.393,4.389
	c2.567,7.239,8.504,10.618,14.667,13.854c4.628,2.43,9.316,4.746,13.977,7.113c-11.4-4.262-23.399-7.4-34.043-13.08
	c-12.375-6.604-23.993-14.88-35.083-23.528c-5.654-4.409-9.434-11.221-14.458-17.627c-1.325-2.983-2.251-5.285-3.178-7.587
	c-4.409-24.815,7.506-42.782,24.56-58.609c1.354-1.256,2.541-2.691,4.483-4.619c8.572-5.14,16.465-9.703,25.26-14.788
	c-0.253-6.769-7.028-5.766-10.99-6.352c-4.062-5.699-7.452-10.455-11.931-16.739c-4.469,6.535-7.853,11.482-10.588,15.482
	c-3.356,1.555-6.092,2.823-8.791,4.157c0.037,0.065,0.18,0.019-0.316-0.431c-1.391,1.604-2.287,3.656-2.871,4.996
	c5.595,2.92,10.089,5.266,14.584,7.669c0,0.058,0.033,0.136,0.355,0.448c1.273,1.435,2.226,2.557,3.177,3.679
	c-3.746,3.062-7.492,6.123-10.16,8.304c-8.975-1.958-17.084-3.727-26.817-5.85c1.506-7.718,3.158-16.183,5.308-25.42
	C312.649,201.268,314.441,198.731,316.233,196.193z"/>
<path fill="#82C7BB" d="M659.077,292.69c-11.626-18.771-18.483-38.499-18.828-60.636c1.656-0.268,3.203-0.604,4.769-0.745
	c1.357-0.122,2.734-0.025,4.65-0.025c1.706,36.222,17.166,64.822,46.307,86.487c27.417-22.321,39.033-51.892,41.144-86.216
	c2.9,0,5.366,0,7.806,0c5.332,59.728-54.291,131.918-140.332,123.08c7.175-6.914,14.913-14.371,22.174-21.366
	c0,2.877,0,7.389,0,12.552c22.476-2.204,42.595-8.744,60.197-21.005C677.596,314.055,668.478,303.581,659.077,292.69z"/>
<path fill="#81C5BA" d="M402.361,354.52c-5.165-2.414-9.853-4.73-14.481-7.16c-6.162-3.236-12.099-6.615-14.667-13.854
	c-0.588-1.658-2.993-2.673-4.93-4.3c-0.203-6.127-0.884-12.115,0.291-17.715c3.29-15.685,16.103-21.409,29.571-23.766
	c13.88-2.429,28.246-2.118,42.406-2.885c4.129-0.224,8.28-0.034,12.703-0.034c0,3.604,0,6.504,0,9.994
	c-10.171,0-19.924-0.264-29.656,0.08c-8.37,0.295-16.773,0.877-25.059,2.058c-12.72,1.812-20.833,10.313-21.22,21.335
	c-0.44,12.564,6.333,21.557,19.39,24.379c7.461,1.613,15.183,2.517,22.816,2.734c14.673,0.418,29.367,0.122,44.878,0.122
	c0-22.575,0-44.79,0-66.808c9.064-2.047,9.691-1.775,9.728,5.801c0.113,23.274,0.041,46.549,0.041,70.739
	c-7.088,0-13.546-0.107-20,0.022c-14.44,0.29-28.877,0.795-43.317,0.97C408.199,356.264,405.529,355.151,402.361,354.52z"/>
<path fill="#80C4B9" d="M315.704,196.075c-1.263,2.655-3.055,5.192-5.207,7.933c-25.095-1.156-49.83-2.514-75.311-3.912
	c0,52.82,0,103.537,0,154.694c-3.317,0-5.981,0-9.141,0c0-54.109,0-108.04,0-162.81c4.182,0,8.07,0,11.959,0
	c16.32,0.001,32.658-0.454,48.953,0.176C296.398,192.522,305.771,194.633,315.704,196.075z"/>
<path fill="#81C6BA" d="M581.66,352.674c-17.814,4.681-34.006,1.338-49.708-8.294c-14.872-9.123-15.338-40.697,0.767-49.354
	c11.187-6.013,22.609-9.621,35.198-9.694c12.533-0.072,25.067-0.016,37.946-0.016c0,3.059,0,5.957,0,9.478
	c-10.152,0-19.901-0.268-29.628,0.081c-8.369,0.299-16.777,0.873-25.056,2.09c-12.858,1.89-20.932,10.732-21.144,22.213
	c-0.215,11.652,7.191,21.005,19.711,23.525c7.938,1.598,16.125,2.028,24.22,2.771c5.537,0.507,11.106,0.649,16.979,1.279
	C588.063,348.944,584.861,350.809,581.66,352.674z"/>
<path fill="#C4CCE3" d="M502.218,251.779c0.101-16.53,0.199-33.06,0.314-49.589c0.001-0.165,0.228-0.329,0.292-0.416
	c6.066,6.702,12.133,13.402,19.139,21.141c-9.439-1.29-9.037,4.101-9.047,9.932c-0.049,31.437-0.133,62.875-0.684,94.305
	c-0.198,11.353-7.517,22.201-17.428,28.05c2.087-6.849,5.527-13.368,5.843-20.035c1.13-23.927,1.203-47.904,1.658-73.142
	C502.265,257.756,502.241,254.767,502.218,251.779z"/>
<path fill="#C7CFE6" d="M581.045,352.752c3.816-1.943,7.018-3.809,10.472-6.016c15.963-11.536,23.588-26.632,22.822-46.054
	c-0.375-9.501-0.054-19.029-0.065-28.546c-0.012-9.062-0.182-9.221-10.157-9.227c1.606-2.696,2.976-4.994,4.972-7.951
	c3.624-2.559,6.62-4.456,9.617-6.354c2.255-3.162,4.51-6.325,7.627-10.697c0.224,3.314,0.463,5.241,0.467,7.168
	c0.03,15.858,0.498,31.735-0.151,47.566c-0.437,10.626-1.754,21.339-3.963,31.739c-3.438,16.182-23.07,31.188-39.535,31.108
	C582.238,355.485,581.336,353.756,581.045,352.752z"/>
<path fill="#83C8BD" d="M314.535,292.696c1.487,2.257,2.414,4.559,3.286,7.334c-9.955,3.166-19.856,5.858-29.902,8.59
	c22.5,17.582,48.248,30.907,77.66,37.268c-0.234,2.021-0.301,3.383-0.572,4.704c-0.274,1.34-0.752,2.637-1.383,4.776
	c-37.488-8.688-70.42-24.933-96.975-53.131c1.86-6.2,4.991-8.069,11.713-6.519C290.334,298.48,302.428,297.611,314.535,292.696z"/>
<path fill="#82C6BB" d="M618.253,248.366c-2.545,2.137-5.541,4.034-8.934,6.183c-6.377-8.151-15.607-10.832-24.868-12.324
	c-9.087-1.465-18.441-1.266-28.167-1.824c0-2.604,0-5.244,0-8.431c19.157-0.861,38.123-1.511,54.888,10.114
	C613.607,243.773,615.603,246.097,618.253,248.366z"/>
<path fill="#83C7BC" d="M346.857,249.314c2.413-2.366,4.826-4.732,7.683-7.225c0.722,1.187,0.999,2.5,1.733,4.028
	c0.442,2.23,0.428,4.246,0.388,6.865c-2.654,11.623-5.652,22.432-13.782,31.023c-1.719,1.815-2.548,4.474-4.408,6.202
	c-4.521-6.273-3.811-9.915-0.802-15.045c4.214-7.184,5.977-15.805,8.322-24.269c-0.466-0.457-0.417-0.352-0.072-0.145
	C346.462,250.409,346.66,249.862,346.857,249.314z"/>
<path fill="#83C8BC" d="M514.888,205.788c9.637-9.479,20.827-13.486,34.339-12.862c0,2.697,0,5.309,0,8.354
	c-2.028,0.475-4.218,1.315-6.448,1.448c-7.472,0.443-13.913,2.912-19.455,8.124c-1.282,1.206-4.555,1.866-5.865,1.066
	C515.901,210.968,515.475,208.167,514.888,205.788z"/>
<path fill="#C6CEE5" d="M395.792,198.078c3.716-7.926,9.466-10.604,14.651-7.044c4.131,2.837,7.226,7.276,4.604,11.777
	c-1.885,3.235-6.768,6.778-10.146,6.619C399.545,209.178,395.187,205.253,395.792,198.078z"/>
<path fill="#85CABE" d="M529.11,261.918c-3.676,0-6.683,0-10.188,0c0-3.298,0-6.191,0-9.563c7.849,0,15.611,0,23.854,0
	c0,2.903,0,5.783,0,9.563C538.462,261.918,534.12,261.918,529.11,261.918z"/>
<path fill="#88CFC2" d="M501.578,251.829c0.663,2.938,0.687,5.927,0.675,9.554c-7.159,0.64-14.284,0.64-21.833,0.64
	c0-3.475,0-6.342,0-10.142C487.243,251.881,494.091,251.881,501.578,251.829z"/>
<path fill="#86B5B6" d="M409.16,230.902c0.348,2.898,0.177,5.98-0.326,9.518c-3.262,0.705-5.588,0.93-5.841-3.467
	C402.725,232.3,405.093,231.427,409.16,230.902z"/>
<path fill="#83C8BC" d="M327.098,211.183c0.405,0.065,0.893,0.392,1.282,0.301c9.735-2.279,15.861,2.076,19.576,10.817
	c-2.877,5.969-5.884-0.616-9.164,0.536c-0.264,0.716-0.297,0.638,0.4,0.412c-1.688-2.398-4.094-4.55-6.451-6.753
	c-1.852-1.731-3.645-3.527-5.464-5.295C327.278,211.201,327.135,211.248,327.098,211.183z"/>
</svg>
);

export default IconLoader;
